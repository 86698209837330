export class AppConfig {

    // start local configs


    // public readonly apiUrl: string = 'http://127.0.0.1:8000/api';
    // public readonly baseUrl: string = 'http://127.0.0.1:8000';
    // public readonly wsocket:string = 'ws://127.0.0.1:8000'
    // public readonly trwsocket:string = 'ws://127.0.0.1:7547/tr069/ws'

    // public readonly tr069_baseUrl: string = 'http://127.0.0.1:7547';
    // public readonly tr069_apiUrl: string = 'http://127.0.0.1:7547/api';  

    //  end local configs// // // 

    // start prod configs

    public readonly tr069_baseUrl: string = 'https://acs.netpap.co.ke';
    public readonly olt_baseUrl: string = 'https://acs.netpap.co.ke';
    public readonly tr069_apiUrl: string = 'https://acs.netpap.co.ke/api';
    public readonly olt_apiUrl: string = 'https://acs.netpap.co.ke/api';
    public readonly trwsocket: string = 'wss://acs.netpap.co.ke/tr069/ws'
    public readonly apiUrl: string = 'https://v4.netpap.co.ke/api';
    public readonly baseUrl: string = 'https://v4.netpap.co.ke';
    public readonly wsocket:string = 'wss://v4.netpap.co.ke'
    public readonly chatUrl: string = 'https://chat.netpap.co.ke';
    public readonly hotanalyticsUrl: string = 'https://hotanalytics.netpap.co.ke/api';
    public readonly reportUrl: string = 'https://monitoring.netpap.co.ke';

    // //  end staging configs// // // 


    // public readonly hotanalyticsUrl: string = 'https://hotanalytics.netpap.co.ke/api';

    // // end changed configs

    // start prod urls

   


    // public readonly apiUrl: string = 'https://staging.netpap.co.keex
    // public readonly chatUrl: string = 'https://chat.netpap.co.ke';
    // public readonly hotanalyticsUrl: string = 'https://hotanalytics.netpap.co.ke/api';
    // public readonly apiUrl: string = 'https://recovered.netpap.co.ke/api';
    // public readonly baseUrl: string = 'https://recovered.netpap.co.ke';


    //ennd prod urls 

    public readonly pageSize: number = 10;
    public readonly pageSizeOptions = [50, 80, 100, 200, 300, 400, 500, 700, 1000, 2000, 5000, 10000, 15000, 30000, 50000];
    public readonly security_token = '123456';

    hide(event) {
        let d = event.target.closest('button')
        d.style.visibility = 'hidden';
    }
    show(event) {
        let d = event.target.closest('button')
        d.style.visibility = '';
    }
}
export class AppVersion {
    public readonly v: string = 'v1';
}


